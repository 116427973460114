export default {
  "buttonProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "buttonContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "designerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ux Ui Designer"])},
  "devText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi there! 🎨✨ I’m Betsa, and I love crafting apps and websites designed with your users in mind. Check out my portfolio and get inspired to bring your ideas to life!."])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "filtertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
  "button01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi there! Thank you for visiting this space. I’d love to share a little more about myself."])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m Betsabé, born in Venezuela, and I’m fortunate to now live in one of my favorite cities: Madrid."])},
  "aboutP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’ve always had a deep passion for design and creativity. That passion led me to study architecture, a field that has brought me great satisfaction and unforgettable experiences. However, my curiosity and eagerness to learn have never stopped."])},
  "aboutP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 2021, I decided to take a new step and dive into the world of digital product design, a field that has allowed me to expand my creative horizons."])},
  "aboutP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Throughout this journey, one thing that caught my attention is how, much like in architecture, a digital product must not only be aesthetically pleasing but also functional. This realization helped me see that many of the tools and principles I learned as an architect could be applied to this new area, allowing me to combine the best of both worlds."])},
  "aboutP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, I balance my work as an architect with freelance web design projects. However, my true goal is to fully dedicate myself to digital design and development, a field I’m deeply passionate about. That’s why I’m seeking collaborations and new job opportunities where I can continue growing, learning, and contributing my experience and creativity."])},
  "happy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design thinking as a tool to solve today's problems"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HappyLife is a project that aims to find a solution to the problem of social isolation, with a design thinking approach."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resumen"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la actualidad, la sociedad enfrenta un problema creciente de aislamiento social. A pesar de los avances tecnológicos que permiten una mayor conectividad virtual, la capacidad de establecer conexiones humanas genuinas parece estar disminuyendo. Las largas jornadas laborales, la urbanización, y el uso intensivo de las redes sociales están creando barreras invisibles que dificultan las interacciones cara a cara."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafíos"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En nuestra investigación inicial, nuestra primera misión fue identificar el problema y tratar de comprender quiénes son nuestros posibles usuarios. Identificar a los usuarios y a los diferentes actores relacionados con el problema nos permite generar ideas de manera mucho más sencilla y responder preguntas como: ¿Por qué?, ¿para quién? y ¿para qué?"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Proposition canvas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL Value Proposition Canvas nos permitió visualizar a los clientes, sus necesidades, sus problemas y cómo una propuesta de valor con características útiles puede ayudar a aliviar esas necesidades."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User persona y User journey"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      }
    ]
  },
  "figy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design of a marketplace app for the sale of phygital and ecological products"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGY is a mobile app, designed as a final project for the Master's Degree in User Experience by Elisava, in Spain. It's a disruptive platform that offers sustainable products associated with an NFT, guaranteeing their traceability."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figy, la tecnología al servicio del planeta"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los hábitos de consumo no sostenibles de la población han provocado numerosos problemas ambientales debido a la demanda excesiva de recursos. Este proyecto surge como una iniciativa para fomentar la economía circular mediante el uso de tecnologías innovadoras como la blockchain, y para promover el consumo responsable y la elección de alternativas ecológicas y sostenibles."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrama de afinidad"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la investigación de usuarios, utilizamos el diagrama de afinidad para organizar todas las ideas surgidas de las entrevistas con los usuarios, clasificándolas por categorías y definiendo prioridades."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User persona"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura de la información"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de pago"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera de activos y Trazabilidad del producto"])}
      }
    ]
  },
  "wand": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design of a domotic application for the control of the connected devices of the house."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project consisted of research, architecture, UX writing, and design of a smart home app for the Master's Degree in Experience Design by ELISAVA."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wand, el asistente del hogar que te ayuda a ganar tiempo libre."])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desafío era crear una aplicación que conecte y controle mágicamente todos los tipos de dispositivos IoT disponibles. Wand debería ser capaz de gestionar una casa, incluyendo plataformas de streaming, con una experiencia transparente para jóvenes y familias tecnológicas que también se interesan en la eficiencia energética y la seguridad doméstica"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrama de afinidad"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la investigación con los usuarios, utilizamos el diagrama de afinidad para organizar todas las ideas surgidas de las entrevistas con los usuarios, clasificándolas por categoría y estableciendo prioridades"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Personas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El user persona es una herramienta de diseño muy poderosa que nos permitió visualizar las interacciones con la nueva aplicación y mitigar los puntos de fricción para los usuarios potenciales."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura de la información"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez definidas las funcionalidades, cada miembro del equipo propuso diferentes maquetas de baja fidelidad para dar forma a la aplicación."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad visual"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz"])}
      }
    ]
  },
  "groupomania": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a corporate social network to improve employee satisfaction."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project completed during the web developer training program at Openclassrooms, in France. The Groupomania group's internal social network, allows to create a user account, connect and participate in the company's internal forum."])}
  },
  "hot": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of an API for a hot sauce web application"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of an API for a web application where users can recommend their favorite hot sauces and rate other users' choices.."])}
  },
  "csm": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web integration challenge, created by the frontend practice website."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web integration challenge, created by the frontend practice website. The exercise consisted of recreating the homepage of Canal Street Market, taking into account the graphic style, colors, typography, animations, etc. The mockup is also responsive."])}
  },
  "cv": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom theme creation on Wordpress."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog created with wordpress. The project consists of the creation and integration of a custom theme for wordpress, the creation of a database, and its online publishing."])}
  },
  "coa": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom website development"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website developed with WordPress and Elementor Pro. COA&co is a new brand of protein chocolates. The design was created by:"])}
  },
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download cv"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch!"])},
  "footerParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions about projects or would like to know more about my work, I'd be happy to help. Contact me!"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])}
}