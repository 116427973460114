export default {
  "buttonProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mí"])},
  "buttonContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
  "designerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño Ux Ui"])},
  "devText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo web."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola! 🎨✨ Soy Betsa, y me especializo en crear aplicaciones y sitios web pensados para tus usuarios. Explora mi portafolio y encuentra la inspiración que necesitas para llevar tus ideas al siguiente nivel! 🚀"])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyectos"])},
  "filtertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo web"])},
  "button01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descubrir"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre mí"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola! Gracias por visitar este espacio. Me encantaría contarte un poco más sobre mí."])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy Betsabé, nací en Venezuela y actualmente tengo la suerte de vivir en una de mis ciudades favoritas: Madrid."])},
  "aboutP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde siempre he sentido una profunda pasión por el diseño y la creatividad. Esa inquietud me llevó a estudiar arquitectura, una carrera que me ha brindado grandes satisfacciones y experiencias inolvidables. Sin embargo, mi curiosidad y ganas de aprender nunca se han detenido. "])},
  "aboutP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En 2021 decidí dar un nuevo paso y explorar el mundo del diseño de productos digitales, un campo que me ha permitido expandir mi horizonte creativo."])},
  "aboutP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lo largo de este camino, una de las cosas que mas llamo mi atención es que al igual que en la arquitectura, un producto digital no solo debe ser estéticamente agradable, sino también funcional, y esto me llevo a entender que muchas de las herramientas y principios que aprendí como arquitecta podían aplicarse en esta nueva área, lo cual me ha permitido combinar lo mejor de ambos mundos."])},
  "aboutP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente compagino mi trabajo como arquitecta con proyectos de diseño web como freelance. Sin embargo, mi verdadera meta es dedicarme por completo al diseño y desarrollo digital, un área que me apasiona profundamente. Por eso, estoy en búsqueda de colaboraciones y nuevas oportunidades laborales donde pueda seguir creciendo, aprendiendo y aportando mi experiencia y creatividad."])},
  "happy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design thinking como una herramienta para resolver los problemas actuales."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HappyLife es un proyecto que tiene como objetivo encontrar una solución al problema del aislamiento social, utilizando herramientas del design thinking."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La epidemia de la soledad"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la actualidad, la sociedad enfrenta un problema creciente de aislamiento social. A pesar de los avances tecnológicos que permiten una mayor conectividad virtual, la capacidad de establecer conexiones humanas genuinas parece estar disminuyendo. Las largas jornadas laborales, la urbanización, y el uso intensivo de las redes sociales están creando barreras invisibles que dificultan las interacciones cara a cara."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafíos"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En nuestra investigación inicial, nuestra primera misión fue identificar el problema y tratar de comprender quiénes son nuestros posibles usuarios. Identificar a los usuarios y a los diferentes actores relacionados con el problema nos permite generar ideas de manera mucho más sencilla y responder preguntas como: ¿Por qué?, ¿para quién? y ¿para qué?"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Proposition canvas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL Value Proposition Canvas nos permitió visualizar a los clientes, sus necesidades, sus problemas y cómo una propuesta de valor con características útiles puede ayudar a aliviar esas necesidades."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User persona y User journey"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      }
    ]
  },
  "figy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de un marketplace para la venta de productos phygitales y ecológicos"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGY es una aplicación móvil, diseñada como proyecto final para el Máster en Experiencia de Usuario de Elisava, en España. Es una plataforma disruptiva que ofrece productos sostenibles asociados a un NFT, garantizando su trazabilidad."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figy, la tecnología al servicio del planeta"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los hábitos de consumo no sostenibles de la población han provocado numerosos problemas ambientales debido a la demanda excesiva de recursos. Este proyecto surge como una iniciativa para fomentar la economía circular mediante el uso de tecnologías innovadoras como la blockchain, y para promover el consumo responsable y la elección de alternativas ecológicas y sostenibles."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrama de afinidad"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la investigación de usuarios, utilizamos el diagrama de afinidad para organizar todas las ideas surgidas de las entrevistas con los usuarios, clasificándolas por categorías y definiendo prioridades."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User persona"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura de la información"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de pago"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera de activos y Trazabilidad del producto"])}
      }
    ]
  },
  "wand": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de una aplicación domótica para controlar los dispositivos conectados del hogar."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este proyecto consistió en el diseño UI y UX de una aplicación para viviendas inteligentes. Incluyó investigación con usuarios, pruebas de usabilidad, diseño de arquitectura web, prototipos y diseño final de la interfaz. Este trabajo se llevó a cabo en un período de 4 meses durante el Máster en Diseño de Experiencia de la escuela ELISAVA."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wand, el asistente del hogar que te ayuda a ganar tiempo libre."])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desafío era crear una aplicación que conecte y controle mágicamente todos los tipos de dispositivos IoT disponibles. Wand debería ser capaz de gestionar una casa, incluyendo plataformas de streaming, con una experiencia transparente para jóvenes y familias tecnológicas que también se interesan en la eficiencia energética y la seguridad doméstica"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrama de afinidad"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la investigación con los usuarios, utilizamos el diagrama de afinidad para organizar todas las ideas surgidas de las entrevistas con los usuarios, clasificándolas por categoría y estableciendo prioridades"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Personas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El user persona es una herramienta de diseño muy poderosa que nos permitió visualizar las interacciones con la nueva aplicación y mitigar los puntos de fricción para los usuarios potenciales."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura de la información"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez definidas las funcionalidades, cada miembro del equipo propuso diferentes maquetas de baja fidelidad para dar forma a la aplicación."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad visual"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz"])}
      }
    ]
  },
  "groupomania": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de una red social corporativa para mejorar la satisfacción de los empleados."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto completado durante el programa de formación de desarrollo web en Openclassrooms, en Francia. La red social interna del grupo Groupomania permite crear una cuenta de usuario, conectarse y participar en el foro interno de la empresa."])}
  },
  "hot": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de una API para una aplicación web que permite valorar salsas picantes."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo de una interfaz de programación de aplicaciones (API) para una aplicación web que permite a los usuarios recomendar sus salsas picantes preferidas y evaluar las selecciones de otros usuarios."])}
  },
  "csm": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafío de integración web, creado por el sitio web de práctica de frontend."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafío de integración web, creado por el sitio web de práctica de frontend. El ejercicio consistió en recrear la página de inicio de Canal Street Market, teniendo en cuenta el estilo gráfico, colores, tipografía, animaciones, etc. El diseño también es responsivo."])}
  },
  "cv": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de un tema personalizado en Wordpress."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog creado con WordPress. El proyecto consiste en la creación e integración de un tema personalizado para WordPress, la creación de una base de datos y su publicación."])}
  },
  "coa": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de un sitio web personalizado."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web desarrollado con WordPress y Elementor pro. COA&co es una nueva marca de chocolates proteicos. El diseño fue realizado por:"])}
  },
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar cv"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctame"])},
  "footerParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes preguntas sobre proyectos o quieres saber más sobre mi trabajo, estaré encantada de ayudarte. ¡Contáctame!"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])}
}