export default {
  "buttonProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "buttonContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "designerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ux Ui Designer"])},
  "devText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégratrice."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut ! 🎨✨ Je suis Betsa, et je crée des applications et des sites web pensés pour vos utilisateurs. Découvrez mon portfolio et trouve l’inspiration pour donner vie à tes idées!"])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
  "filtertext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégration"])},
  "button01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["decouvrir"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour, Merci de visiter cet espace. J’aimerais vous en dire un peu plus sur moi."])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je m’appelle Betsabé, je suis née au Venezuela et j’ai la chance de vivre aujourd’hui dans l’une de mes villes préférées : Madrid."])},
  "aboutP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’ai toujours eu une passion profonde pour le design et la créativité. Cette passion m’a poussée à étudier l’architecture, une discipline qui m’a apporté beaucoup de satisfaction et des expériences inoubliables. Cependant, ma curiosité et mon envie d’apprendre ne se sont jamais arrêtées."])},
  "aboutP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En 2021, j’ai décidé de franchir une nouvelle étape et d’explorer le monde du design de produits numériques, un domaine qui m’a permis d’élargir mes horizons créatifs."])},
  "aboutP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au cours de ce parcours, une chose qui a particulièrement retenu mon attention est que, tout comme en architecture, un produit numérique ne doit pas seulement être esthétique, mais aussi fonctionnel. Cela m’a permis de comprendre que de nombreux outils et principes appris en tant qu’architecte pouvaient être appliqués à ce nouveau domaine, me permettant ainsi de combiner le meilleur des deux mondes."])},
  "aboutP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuellement, je partage mon temps entre mon travail d’architecte et des projets de conception web en freelance. Toutefois, mon véritable objectif est de me consacrer entièrement au design et au développement numérique, un domaine qui me passionne profondément. C’est pourquoi je suis à la recherche de collaborations et de nouvelles opportunités professionnelles où je pourrais continuer à grandir, apprendre et apporter mon expérience ainsi que ma créativité."])},
  "happy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le design thinking comme outil de résolution des problèmes."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Happy life est un projet qui visait à comprendre et à trouver des solutions innovantes aux problèmes d'isolement social résultant de la pandémie de Covid 19. À cette fin, une recherche sur les utilisateurs et différents exercices de réflexion ont été réalisés en utilisant le design thinking comme instrument."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resumen"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la actualidad, la sociedad enfrenta un problema creciente de aislamiento social. A pesar de los avances tecnológicos que permiten una mayor conectividad virtual, la capacidad de establecer conexiones humanas genuinas parece estar disminuyendo. Las largas jornadas laborales, la urbanización, y el uso intensivo de las redes sociales están creando barreras invisibles que dificultan las interacciones cara a cara."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafíos"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En nuestra investigación inicial, nuestra primera misión fue identificar el problema y tratar de comprender quiénes son nuestros posibles usuarios. Identificar a los usuarios y a los diferentes actores relacionados con el problema nos permite generar ideas de manera mucho más sencilla y responder preguntas como: ¿Por qué?, ¿para quién? y ¿para qué?"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Proposition canvas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL Value Proposition Canvas nos permitió visualizar a los clientes, sus necesidades, sus problemas y cómo una propuesta de valor con características útiles puede ayudar a aliviar esas necesidades."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User persona y User journey"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      }
    ]
  },
  "figy": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conception d'une marketplace app pour la vente de produits phygitaux et écologiques."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGY est une application mobile, conçue comme projet de fin d'études pour le Mastère en expérience utilisateur d'Elisava. Il s'agit d'une plateforme disruptive qui propose des produits écologiques associés à un NFT, garantissant leur traçabilité."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resumen"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la actualidad, la sociedad enfrenta un problema creciente de aislamiento social. A pesar de los avances tecnológicos que permiten una mayor conectividad virtual, la capacidad de establecer conexiones humanas genuinas parece estar disminuyendo. Las largas jornadas laborales, la urbanización, y el uso intensivo de las redes sociales están creando barreras invisibles que dificultan las interacciones cara a cara."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desafíos"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En nuestra investigación inicial, nuestra primera misión fue identificar el problema y tratar de comprender quiénes son nuestros posibles usuarios. Identificar a los usuarios y a los diferentes actores relacionados con el problema nos permite generar ideas de manera mucho más sencilla y responder preguntas como: ¿Por qué?, ¿para quién? y ¿para qué?"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Proposition canvas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL Value Proposition Canvas nos permitió visualizar a los clientes, sus necesidades, sus problemas y cómo una propuesta de valor con características útiles puede ayudar a aliviar esas necesidades."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User persona y User journey"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz (primeras ideas)"])}
      }
    ]
  },
  "wand": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conception d'une application domotique pour le contrôle des appareils connectés de la maison."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wand est un projet qui consistait en la création d'une application pour une maison intelligente. L'objectif du client était de centraliser l'utilisation de tous les objets connectés d'une maison, même s'ils appartiennent à des marques différentes, dans une seule application."])},
    "infoByProject": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wand, el asistente del hogar que te ayuda a ganar tiempo libre."])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El desafío era crear una aplicación que conecte y controle mágicamente todos los tipos de dispositivos IoT disponibles. Wand debería ser capaz de gestionar una casa, incluyendo plataformas de streaming, con una experiencia transparente para jóvenes y familias tecnológicas que también se interesan en la eficiencia energética y la seguridad doméstica"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagrama de afinidad"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la investigación con los usuarios, utilizamos el diagrama de afinidad para organizar todas las ideas surgidas de las entrevistas con los usuarios, clasificándolas por categoría y estableciendo prioridades"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Personas"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El user persona es una herramienta de diseño muy poderosa que nos permitió visualizar las interacciones con la nueva aplicación y mitigar los puntos de fricción para los usuarios potenciales."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura de la información"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wireframes"])},
        "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez definidas las funcionalidades, cada miembro del equipo propuso diferentes maquetas de baja fidelidad para dar forma a la aplicación."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad visual"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de interfaz"])}
      }
    ]
  },
  "groupomania": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un réseau social d'entreprise pour améliorer la satisfaction des employés."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet accompli dans le cadre de la formation de développeur web chez Openclassrooms, en France. Le réseau social interne du groupe Groupomania, permet de créer un compte utilisateur, de se connecter et de participer au forum interne de l'entreprise."])}
  },
  "hot": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une API pour une application web de sauces piquantes."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'une API pour une application web où les utilisateurs peuvent recommander leurs sauces piquantes préférées et également noter les sauces postées par d'autres utilisateurs."])}
  },
  "csm": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défi d'intégration web, créé par le site web de frontend practice."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défi d'intégration web, proposé par le site frontendpractice.com. L'exercice consistait à recréer la page d'accueil de canal street market, en prenant en compte le style graphique, les couleurs, la typographie, les animations, etc. La maquette est également responsive"])}
  },
  "cv": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de thème personnalisé sur Wordpress."])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog créé avec wordpress. Le projet consiste en la création et l'intégration d'un thème personnalisé pour wordpress, la création d'une base de données, et sa mise en ligne."])}
  },
  "coa": {
    "homeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom website development"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web développé avec WordPress et Elementor Pro. COA&co est une nouvelle marque de chocolats protéinés. Le design a été réalisé par"])}
  },
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["télécharger cv"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte-moi !"])},
  "footerParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu as des questions sur mes projets ou si tu veux en savoir plus sur mon travail, je serais ravie de t’aider. Contacte-moi !"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])}
}